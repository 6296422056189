<div class="rise-data-table-container attribute-editor-component data-table-component" rvSpinner="data-table-editor-loader" [rvShowSpinner]="spinner">
  @if(!source && !componentAccount) {
    <div class="attribute-editor-row pt-3">
      <label>Edit data</label>
    </div>
    <div #scrollContainer class="templates-selector te-scrollable-container">
      <div (rvSortable)="sortItem($event)" appendTo=".component-container" class="sortable-list">
        @for (item of values; track item; let key = $index) {
          <div class="row templates-row rv-sortable-item row-hover">
            <div class="rv-sortable-handle">
              <streamline-icon class="streamline-component-icon" name="sortableHandle" width="14" height="14"></streamline-icon>
            </div>
            <div class="mr-auto w-100 mb-2">
              @for (field of fields; track field; let key = $index) {
                <div>
                  <div class="flex-row mt-2">
                    <label class="data-table-label">{{ field.name }}:</label>
                    <input [shown]="field.type === 'text'" type="text" class="form-control" [(ngModel)]="item[key]" placeholder="Enter {{ field.name }}" (ngModelChange)="save()">
                    <textarea [shown]="field.type === 'multiline'" class="form-control resize-vertical" rows="2" [(ngModel)]="item[key]" placeholder="Enter {{ field.name }}" (ngModelChange)="save()"></textarea>
                    <div class="input-group w-100" style="margin-left: -2px" [shown]="field.type === 'image'">
                      <input type="text" class="form-control" [(ngModel)]="item[key]" placeholder="Enter URL" (ngModelChange)="save()">
                      <span class="input-group-addon u_clickable" (click)="selectFromStorage(item, key)" title="Select from Storage">
                        <streamline-icon name="riseStorage" width="16" height="16"></streamline-icon>
                      </span>
                    </div>
                  </div>
                  <div [ngClass]="{ 'has-error': invalidUrls.has(item[key])}">
                    <p [shown]="field.type === 'image'" class="help-block">
                      @switch (invalidUrls.get(item[key])) {
                        @case ('INVALID_PROTOCOL') {
                          <span>URL should start with "https://""</span>
                        }
                        @case ('INVALID_EXTENSION') {
                          <span>Image type is invalid. Valid types: JPG,JPEG,PNG,BMP,SVG,GIF,WEBP</span>
                        }
                      }
                    </p>
                  </div>
                </div>
              }
            </div>
            <a href="#" (click)="removeItem(key)">
              <streamline-icon class="ml-3 streamline-component-icon" name="trash" width="16" height="18"></streamline-icon>
            </a>
          </div>
        }
      </div>
      <div class="attribute-editor-row">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <div class="flex-row flex-wrap space-between mt-3">
                <button id="te-data-table-add-row" class="btn btn-primary btn-fixed-width" (click)="addRow()">Add Row</button>
                <a id="export-csv" href="" class="madero-link u_clickable" (click)="exportCsv()" download="data.csv">Export as CSV</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="attribute-editor-component" >
      <div class="attribute-editor-row">
        <label for="te-data-table-upload-csv" class="btn btn-default btn-block mb-3">Upload CSV</label>
        <input id="te-data-table-upload-csv" type="file" [hidden]="true" (change)="importCsv($event)" accept=".csv">
        @if (featuresService.isFeatureAvailable("google_sheets")) {
          <button id="te-data-table-select-google-sheet" class="btn btn-primary btn-block" (click)="selectGoogleSheet()" *requireRole="'sa'">
            Select Google Sheet
          </button>
        }
        @else {
          <button id="te-data-table-select-google-sheet" class="btn btn-primary btn-block feature-disabled" (click)="featuresService.showUpgradePlanModal('google_sheets')" *requireRole="'sa'">
            Select Google Sheet
            <upgrade-notice feature="google_sheets" class="ml-3 mt-2"></upgrade-notice>
          </button>
        }

      </div>
    </div>
  }

  <!-- Connected Account & File Options UI-->
  @if(source || componentAccount) {
    <div class="attribute-editor-component">
      <div class="attribute-editor-row">
        <div class="row">
          <div class="col-xs-12">
            <label class="pb-3">My Sheet File</label>
            <div class="provider-connected-container form-group">
              <div class="account-info">
                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg" alt="Google" class="account-logo">
                <span class="account-email">{{componentAccountUsername}}</span>
              </div>
              <div class="provider-connected-file-info">
                <div class="file-details">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Google_Sheets_2020_Logo.svg" class="file-icon">
                  <div class="file-text">
                    @if (!source) {
                      <span class="file-item-count">No file selected</span>
                    }
                    @else {
                      <span class="file-title">{{selectedName}}</span>
                      @if (sheetValues.rowCount > 500) {
                        <span class="file-item-count">500 rows</span>
                      }
                      @else {
                        <span class="file-item-count">{{sheetValues.rowCount}} rows</span>
                      }

                    }

                  </div>
                </div>
                <button class="btn btn-icon pr-0" mat-icon-button [matMenuTriggerFor]="fileOptions" (menuOpened)="onDropdownOpen()">
                  <i class="fa fa-chevron-down file-options-icon" [ngClass]="{'clicked': isDropdownOpen}"></i>
                </button>
                <mat-menu #fileOptions="matMenu" xPosition="before" (closed)="onDropdownClose()">
                  <button mat-menu-item (click)="changeFile()">
                    <streamline-icon class="mr-3 streamline-component-icon" name="fileInverted" width="16" height="16"></streamline-icon>
                    <span>Select a different sheet</span>
                  </button>
                  @if (source && !fileAccessFailed) {
                    <button mat-menu-item (click)="openGoogleSheet()">
                      <streamline-icon class="mr-3 streamline-component-icon" name="arrowDiagonalRight" width="16" height="16"></streamline-icon>
                      <span>Open in Google Sheets</span>
                    </button>
                  }
                  <button mat-menu-item (click)="editManually()">
                    <streamline-icon class="mr-3 streamline-component-icon" name="editSheet" width="16" height="16"></streamline-icon>
                    <span>Unlink sheet and edit manually</span>
                  </button>
                  @if(sameAccount) {
                    <hr class="m-2">
                    <button mat-menu-item (click)="confirmDisconnect()">
                      <streamline-icon class="mr-3 streamline-component-icon" name="unlink" width="16" height="16"></streamline-icon>
                      <span>Disconnect from Google</span>
                    </button>
                  }
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      @if(fileAccessFailed) {
        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
                <div class="provider-file-error has-error">
                  <streamline-icon class="error-icon mr-3" name="xmark" width="24" height="24"></streamline-icon>
                  <div>
                    <p class="help-block mt-1 font-weight-bold">Uh oh! We can't find your file.</p>
                    <p>It looks like it might have been moved or been deleted. Select another Google Sheet to update your presentation.</p>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div class="attribute-editor-row">
        @if (helpText) {
          <p>{{helpText}}</p>
        }
        @else {
          <p>Edit your Google Sheet to update the list (updates every 10 minutes).</p>
        }
       </div>
    </div>
    <div class="attribute-editor-row">
      <div class="flex-row left-right-aligner pt-3">
        <label for="te-data-table-first-row" id="data-table-first-row-label" class="mb-0">Hide first row</label>
        <mat-slide-toggle id="te-data-table-first-row" class="pl-3" [checked]="sheetValues.hideFirstRow" (change)="hideFirstRowChanged()" ariaLabelledby="data-table-first-row-label"></mat-slide-toggle>
      </div>
    </div>
  }
</div>
