import { ElementRef, Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AttributeDataService } from '../../services/attribute-data.service';
import { BlueprintService } from '../../services/blueprint.service';
import { ComponentsService } from '../../services/components.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import * as csv from 'jquery-csv';
import { OAuthService } from 'src/app/components/content-oauth/services/oauth.service';
import { GoogleSheetsService } from '../services/google-sheets.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { GoogleDrivePickerService } from '../services/google-drive-picker.service';
import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { FeaturesService } from 'src/app/components/plans/features.service';

const PROVIDER_GOOGLE_SHEETS = 'google';
const SCOPE_GOOGLE_PICKER = 'https://www.googleapis.com/auth/drive.file';
const SCOPE_SHEETS_READONLY = 'https://www.googleapis.com/auth/spreadsheets.readonly';
const SCOPE_DATA_TABLE = `${SCOPE_GOOGLE_PICKER} ${SCOPE_SHEETS_READONLY}`;

enum RestrictionMessageType {
  Rows,
  Columns,
}

@Component({
  selector: 'template-component-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent {

  componentId;
  fields = [];
  values = [];
  invalidUrls = new Map();

  public componentAccount: string;
  public componentAccountUsername: string;
  public selectedName: string;
  public source: string;
  public isDropdownOpen: boolean;
  public revokeFailed: boolean;
  public authenticateFailed: boolean;
  public fileAccessFailed: boolean;
  public spinner: boolean;
  public helpText: string;

  public sheetValues = {
    rowCount: 0,
    hideFirstRow: true,
  };

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(
    private elementRef: ElementRef,
    private componentsService: ComponentsService,
    private attributeDataService: AttributeDataService,
    private blueprintService: BlueprintService,
    private templateEditorService: TemplateEditorService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: ModalService,
    private storageManagerService: StorageManagerService,
    private storageUtilsService: StorageUtilsService,
    private authService: OAuthService,
    private googleSheetsService: GoogleSheetsService,
    private userStateService: UserStateService,
    private googleDrivePickerService: GoogleDrivePickerService,
    private analyticsFactory: AnalyticsFactory,
    private companyStateService: CompanyStateService,
    public featuresService: FeaturesService,
  ) {
    this.isDropdownOpen = false;
    this.componentAccount = null;
    this.componentAccountUsername = null;
    this.selectedName = null;
    this.revokeFailed = false;
    this.authenticateFailed = false;
    this.fileAccessFailed = false;

    componentsService.registerDirective({
      type: 'rise-data-table',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsService.selected.id;
        this.helpText = this.blueprintService.getHelpText(this.componentId);
        this._load();
      }
    });
  }

  get sameAccount(): boolean {
    const userAccount = this.authService.getUserIdentifier(null);
    return userAccount === this.componentAccount;
  }

  async _load() {
    const values = this.attributeDataService.getAvailableAttributeData(this.componentId, 'values');
    const fields = this.attributeDataService.getAvailableAttributeData(this.componentId, 'fields');

    this.source = this.attributeDataService.getAvailableAttributeData(this.componentId, 'source');
    this.componentAccount = this.attributeDataService.getAvailableAttributeData(this.componentId, 'account');
    this.selectedName = this.attributeDataService.getAvailableAttributeData(this.componentId, 'name') || '';

    this.sheetValues.rowCount = 0;
    const hideFirstRow = this.attributeDataService.getAvailableAttributeData(this.componentId, 'hideFirstRow');
    this.sheetValues.hideFirstRow = hideFirstRow === undefined ? true : hideFirstRow;

    this.componentAccount = this.attributeDataService.getAvailableAttributeData(this.componentId, 'account');


    this.values = values ? JSON.parse(values) : [];
    this.fields = fields ? JSON.parse(fields) : [];
    this.replaceUnsupportedFieldTypes();

    if (this.componentAccount) {
      this.spinner = true;

      try {
        await this._validateCredentials();
        if (this.source) {
          const sheetValues = await this.googleSheetsService.getData(this.componentAccount, this.source);
          this.sheetValues.rowCount = sheetValues.length;
        }
      } catch (error) {
        this.fileAccessFailed = true;
      } finally {
        this.spinner = false;
        this.refreshUI();
      }
    }

    this.validate();
  }

  refreshUI() {
    this.changeDetectorRef.detectChanges();
  }

  save() {
    if (!this.source && this.validate()) {
      this.attributeDataService.setAttributeData(this.componentId, 'values', JSON.stringify(this.values));
    }

    this.attributeDataService.setAttributeData(this.componentId, 'environment', environment.production ? 'prod' : 'test');
    this.attributeDataService.setAttributeData(this.componentId, 'account', this.componentAccount);
    this.attributeDataService.setAttributeData(this.componentId, 'source', this.source);
    this.attributeDataService.setAttributeData(this.componentId, 'name', this.selectedName);
    this.attributeDataService.setAttributeData(this.componentId, 'hideFirstRow', this.sheetValues.hideFirstRow);

    this.refreshUI();
  }

  private _validateCredentials() {

    // get username of a user who configured the component
    return this.authService.getUsername(PROVIDER_GOOGLE_SHEETS, this.componentAccount)
      .then(({authenticated, username}) => {
        this.componentAccountUsername = username;
      })
      .catch(() => {
        console.log('Failed to get username');
      });
  }

  private _connectAccount(provider, scope) {
    return this.authService.getConnectionStatus(provider, scope)
    .catch(() => {
      return this.authService.authenticate(provider, scope);
    });
  }

  private _revoke() {
    this.revokeFailed = false;

    return this.googleSheetsService.revoke(this.authService.getUserIdentifier(PROVIDER_GOOGLE_SHEETS))
    .then((revoked: boolean) => {

      if (!revoked) {
        console.log('Token could not be revoked');

        this.revokeFailed = true;
      } else if (!this.userStateService.isRiseAuthUser()) {
        this._reloadWindow();
      }
    })
    .catch(() => {
      this.revokeFailed = true;
    });
  }

  private _reloadWindow() {
    window.location.reload();
  }

  private _resetSource() {
    this.source = null;
    this.selectedName = null;
    this.fileAccessFailed = false;
  }

  private _resetComponentAccount() {
    this.componentAccount = null;
    this.componentAccountUsername = null;
  }

  addRow() {
    this.values.push([]);
    setTimeout(() => {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    });
  }

  sortItem(evt) {
    this.moveItem(evt.data.oldIndex, evt.data.newIndex);

    this.save();
  }

  moveItem(oldIndex, newIndex) {
    this.values.splice(newIndex, 0, this.values.splice(oldIndex, 1)[0]);
  }

  removeItem(key) {
    this.values.splice(key, 1);

    this.save();
  }

  replaceUnsupportedFieldTypes() {
    //replace unrecognised field types with "text"
    const validFieldTypes = ["text", "multiline", "image"];
    this.fields.forEach(item => {
      if (!validFieldTypes.includes(item.type)) {
        item.type = "text";
      }
    });
  }

  validate() {
    this.invalidUrls.clear();

    this.values.forEach(row => {
      row.forEach((value, index) => {
        if (this.fields[index]?.type === 'image') {
          this.validateImagelUrl(value);
        }
      });
    });
    return this.invalidUrls.size === 0;
  }

  validateImagelUrl(url) {
    //check if url is null, undefuned, or empty
    if (!url?.length) {
      return true;
    }

    //check protocol
    if (!url.toLowerCase().startsWith('https://')) {
      this.invalidUrls.set(url, 'INVALID_PROTOCOL');
      return false;
    }

    //check protocl
    if (!new RegExp(/^.+\.(JPG|JPEG|PNG|BMP|SVG|GIF|WEBP)$/,'i').test(url)) {
      this.invalidUrls.set(url, 'INVALID_EXTENSION');
      return false;
    }

    return true;
  }

  prepareCsv() {
    const columnNames = this.fields.map((item) => item.name);
    const headers = csv.fromArrays([columnNames]);
    const body = csv.fromArrays(this.values);
    return headers + body;
  }

  getCsvDownloadUrl() {
    const text = this.prepareCsv();
    const data = new Blob([text], {type: 'text/plain'});

    return window.URL.createObjectURL(data);
  }

  exportCsv() {
    const url = this.getCsvDownloadUrl();
    const link = window.document.getElementById('export-csv');
    if (link instanceof HTMLAnchorElement ) {
      link.href = url;
    }
  }

  importCsv(e) {

    if (!e.srcElement.value) {
      return;
    }

    this.readFileIntoMemory(e.target.files[0], res => {
      let newValues;

      try {
        if (!res) {
          throw new Error('Error loading file');
        }

        newValues = this.parseCsv(res.content, this.fields.length);

      } catch (error) {
        console.error(error);
        this.modalService.showMessage(error.message, null);
        return;

      } finally {
        //reset input value in order for onChange to trigger when the same file is selected again
        e.srcElement.value = '';
      }

      this.values = newValues;

      this.save();
      this._track();
    });
  }

  parseCsv(data, numberOfColumns) {

    let result;

    try {
      result = csv.toArrays(data);
    } catch (error) {
      throw new Error('Error parsing file content. Make sure you selected the valid CSV file.');
    }

    if (!result.length) {
      throw new Error('CVS file is empty.');
    }

    if (result.length > 500) {
      throw new Error('Too many rows. The maximum is 500.');
    }

    result.forEach((row, rowIndex) => {
      if (row.length != numberOfColumns) {
        throw new Error(`The number of columns in the row ${rowIndex + 1} does not match the number of columns in the table component.`);
      }
    });

    //remove headers
    result.shift();

    return result;
  }

  validateSheetData(data) {
    if (data.length > 500) {
      this.showRestrictionMessage(RestrictionMessageType.Rows);
      return false;
    }

    if (data.length > 0 && data[0].length > this.fields.length) {
      this.showRestrictionMessage(RestrictionMessageType.Columns);
      return false;
    }

    return true;
  }

  readFileIntoMemory (file, callback) {
    var reader = new FileReader();
    reader.onload = () => {
      callback({
        name: file.name,
        size: file.size,
        type: file.type,
        content: reader.result
      });
    };
    reader.onerror = () => {
      callback(null);
    };
    reader.readAsText(file);
  }

  selectFromStorage (item: any, key: number) {
    this.storageManagerService.fileType = StorageManagerService.FILE_TYPE.IMAGE;
    this.storageManagerService.isSingleFileSelector = () => { return true; };
    this.storageManagerService.onSelectHandler = (result) => {
      item[key] = StorageUtilsService.STORAGE_FILE_URL + this.storageUtilsService.getFilePath(result[0]);
      this.save();
    };
    this.componentsService.editComponent({
      type: 'rise-storage-selector'
    });
  }

  async selectGoogleSheet () {

    this.authenticateFailed = false;
    this.spinner = true;

    try {
      const authResult = await this._connectAccount(PROVIDER_GOOGLE_SHEETS, SCOPE_DATA_TABLE);

      if (!this.sameAccount) {
        this.componentAccount = this.authService.getUserIdentifier(null);
        this.componentAccountUsername = authResult.username;
        this._resetSource();
        this.save();
      }

      const signature = await this.googleSheetsService.getSignature(this.componentAccount, PROVIDER_GOOGLE_SHEETS);
      const accessToken = await this.googleSheetsService.getToken(this.componentAccount, PROVIDER_GOOGLE_SHEETS, signature);

      const selectedFile : any = await this.googleDrivePickerService.open(accessToken);
      if (selectedFile) {
        this.source = selectedFile.id;
        this.selectedName = selectedFile.name;

        try {
          const sheetValues = await this.googleSheetsService.getData(this.componentAccount, this.source);
          this.sheetValues.rowCount = sheetValues.length;
          this.validateSheetData(sheetValues);
        } catch (error) {
          this.fileAccessFailed = true;
        }

        // call save after calling googleSheetsService.getData in order to prevent 409 error
        // caused by concurrent requests to Google Service from the Apps and component
        this.save();
        this._track();
      }
    } finally {
      this.spinner = false;

      this.refreshUI();
    }
  }

  changeFile() {
    this.selectGoogleSheet();
  }

  openGoogleSheet() {
    window.open('https://docs.google.com/spreadsheets/d/' + this.source, '_blank');
  }

  async editManually() {
    if (this.source) {
      try {
        this.spinner = true;
        this.values = await this.googleSheetsService.getData(this.componentAccount, this.source);
        if (this.sheetValues.hideFirstRow && this.values?.length > 0) {
          this.values.shift();
        }
      } catch (error) {
        console.log('Failed to load data from google sheets');
      } finally {
        this.spinner = false;
        this.refreshUI();
      }
    }
    this._resetComponentAccount();
    this._resetSource();
    this.save();
  }

  confirmDisconnect() {
    let disconnectMessage = `Any content that is using this Google Sheets account will stop working.`;

    if (!this.userStateService.isRiseAuthUser()) {
      disconnectMessage += ' If this account is the same as the one you use to Sign In to Rise Vision,'
        + ' it will also log you out of the application. You will be prompted to Sign In again if that happens.';
    }

    return this.modalService.confirm(`Disconnect from Google Sheets`, disconnectMessage)
    .then(() => {
      return this._disconnect();
    })
    .catch(() => {});
  }

  private async _disconnect() {

    await this.editManually();

    try {
      this.spinner = true;

      this.templateEditorService.hasUnsavedChanges = true;

      await this.templateEditorService.save();
      await this._revoke();

    } finally {
      this.spinner = false;
    }
  }

  private _track() {
    this.analyticsFactory.track('Data Table Component Updated', {
      companyId: this.companyStateService.getSelectedCompanyId(),
      source: this.source ? "Google Sheet" : "CSV"
    });
  }

  hideFirstRowChanged() {
    this.sheetValues.hideFirstRow = !this.sheetValues.hideFirstRow;

    this.save();
  }

  onDropdownOpen() {
    this.isDropdownOpen = true;
  }

  onDropdownClose() {
    this.isDropdownOpen = false;
  }

  showRestrictionMessage(type: RestrictionMessageType) {
    switch (type) {
      case RestrictionMessageType.Rows:
        this.modalService.showMessage("Too many rows. The maximum is 500.", null);
        break;
      case RestrictionMessageType.Columns:
        this.modalService.showMessage("Extra columns will not be shown", `Your sheet file has too many columns. You can choose a sheet with up to ${this.fields.length} columns.`);
        break;
      default:
        console.error('Unknown restriction type:', type);
    }
  }

  showAuthenticationErrorMessage() {
    this.modalService.showMessage("Authentication failed", "It seems there's an issue with authentication. Please try again. If this problem persists, contact Support.");
  }

  showRevokeErrorMessage() {
    this.modalService.showMessage("Disconnect failed", "We could not revoke your access with Google at this time. We strongly recommend you manually revoke access in your Google account.");
  }

}
